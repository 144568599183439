import { ReactNode, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { Badge, Box, Drawer, Link, Theme, useMediaQuery } from '@mui/material'
import { BarChart2, Book, DollarSign, Home, Layout, List, Mail, Percent, Repeat, Settings, Users } from 'react-feather'
import { DashboardSidebarSection } from './dashboard-sidebar-section'
import { trpc } from '../../../core/trpc'
import { FindInPage, RocketLaunchOutlined, ShoppingCartOutlined, AccountBox, AnalyticsOutlined } from '@mui/icons-material'
import { useSession } from 'next-auth/react'
import { useEmailStore } from '../../../stores/emailStore'
import { SendOutlinedIcon } from '../../../assets/icons'
import { AbilityContext } from '../../../contexts/AbilityContext'
import { features } from '../../../consts/features'

interface DashboardSidebarProps {
  onClose: () => void
  open: boolean
}
interface Item {
  title: string
  onClick?: () => void
  children?: Item[]
  chip?: ReactNode
  icon?: ReactNode
  path?: string
  hidden?: boolean
  divider?: boolean
}

interface Section {
  title: string
  items: Item[]
}

function DashboardSidebar(props: DashboardSidebarProps) {
  const { onClose, open } = props
  const session = useSession()
  const { unreadEmailCount } = useEmailStore()
  const ability = useContext(AbilityContext)
  const { data: newDeals } = trpc.invoice.getOffersRequiringInvoice.useQuery(
    { dateFundedStart: new Date('2024-11-01') },
    { enabled: Boolean(ability.can('create', 'Invoice') && features.accounting.sidebar) }
  )
  const pipelines = trpc.stage.getStagesGroupedByPipeline.useQuery({ include_fallout: true })
  const regularPipelines = pipelines.data?.filter((pipeline) => !pipeline.is_fallout_pipeline)
  const falloutPipelines = pipelines.data?.filter((pipeline) => pipeline.is_fallout_pipeline)
  const subsidiaries = trpc.subsidiary.getSubsidiaryList.useQuery()

  const showAccountingForAdmins = features.accounting.sidebar && ability.can('view', 'Invoice')

  const getChildPipelines = (id: string) => {
    return [
      ...(regularPipelines?.map((pipeline) => ({
        title: pipeline.name,
        path: `/subsidiary/${id}/pipeline/${pipeline.id}/leads/view/default`,
        onClick: () => onClose()
      })) || []),
      ...(falloutPipelines?.map((pipeline, index) => ({
        divider: index === 0,
        title: pipeline.name,
        path: `/subsidiary/${id}/pipeline/${pipeline.id}/leads/view/default`,
        onClick: () => onClose()
      })) || [])
    ]
  }

  const subsidiariesFormatted = subsidiaries?.data?.map((s) => ({ ...s, pipelines: getChildPipelines(s.id) })) || []

  const sections: Section[] = [
    {
      title: '',
      items: [
        {
          title: 'Home',
          icon: <Home />,
          path: '/home',
          onClick: () => onClose()
        },
        {
          title: 'Inbox',
          icon: (
            <Badge badgeContent={unreadEmailCount} color="error" max={99}>
              <Mail />
            </Badge>
          ),
          path: '/mail/inbox',
          onClick: () => onClose()
        },
        {
          title: 'Sent Items',
          icon: <SendOutlinedIcon />,
          path: '/mail/sentItems',
          onClick: () => onClose()
        },
        {
          icon: <Layout />,
          title: 'Pipelines',
          children: [
            ...subsidiariesFormatted.map((s) => ({
              title: `${s.name} Leads`,
              path: `/subsidiary/${s.id}`,
              onClick: () => onClose(),
              children: [...s.pipelines]
            }))
          ]
        },
        {
          title: 'Shop Deals',
          icon: <ShoppingCartOutlined />,
          path: '/shop-deal-drafts',
          hidden: !ability.can('shop_deals', 'Lead'),
          onClick: () => onClose()
        },
        {
          title: 'Analytics',
          icon: <BarChart2 />,
          hidden: !session?.data?.currentRole?.is_admin,
          children: [
            {
              title: 'Dashboard',
              path: '/analytics/powerbi',
              onClick: () => onClose()
            },
            {
              title: 'Reports',
              path: '/analytics/browse',
              onClick: () => onClose()
            }
          ]
        },
        {
          title: 'Tasks',
          icon: <List />,
          path: '/tasks',
          onClick: () => onClose()
        },
        {
          title: 'Contacts',
          icon: <Users />,
          path: '/contacts',
          onClick: () => onClose()
        },
        {
          title: 'Offers',
          icon: <DollarSign />,
          path: '/offers',
          hidden: !ability.can('view', 'Offer'),
          onClick: () => onClose()
        },
        {
          title: 'Funders',
          icon: <RocketLaunchOutlined />,
          path: '/funders',
          hidden: !ability.can('view', 'Funder'),
          onClick: () => onClose()
        },
        {
          title: 'New Deals',
          icon: (
            <Badge color="error" badgeContent={newDeals?.count}>
              <BarChart2 />
            </Badge>
          ),
          path: '/accounting/new-deals',
          hidden: !ability.can('create', 'Invoice') || !showAccountingForAdmins,
          onClick: () => onClose()
        },
        {
          title: 'Invoices',
          icon: <Book />,
          path: '/accounting/invoices',
          hidden: !ability.can('view', 'Invoice') || !showAccountingForAdmins,
          onClick: () => onClose()
        },
        {
          title: 'Commissions',
          icon: <Percent />,
          path: '/accounting/commissions',
          hidden: !ability.can('full', 'Invoice') || !showAccountingForAdmins,
          onClick: () => onClose()
        },
        {
          title: 'Brokers',
          icon: <List />,
          path: '/accounting/brokers',
          hidden:
            (!session.data?.user.isSubsidiaryPartner && !session.data?.user.subordinatesCount && !ability.can('full', 'Invoice')) ||
            !showAccountingForAdmins,
          onClick: () => onClose()
        },
        {
          title: 'Broker',
          icon: <AccountBox />,
          path: `/accounting/broker/${session.data?.user.id}`,
          hidden: session.data?.user.isSubsidiaryPartner || !showAccountingForAdmins,
          onClick: () => onClose()
        },
        {
          title: 'Partners',
          icon: <Users />,
          path: `/accounting/partners`,
          hidden: (!session.data?.user.isSubsidiaryPartner && !ability.can('full', 'Invoice')) || !showAccountingForAdmins,
          onClick: () => onClose()
        },
        {
          title: 'Expenses',
          icon: <DollarSign />,
          path: '/accounting/expenses',
          hidden: !ability.can('view', 'Expense') || !showAccountingForAdmins,
          onClick: () => onClose()
        },
        {
          title: 'Transactions',
          icon: <Repeat />,
          path: '/accounting/transactions',
          hidden: !ability.can('edit', 'Invoice') || !showAccountingForAdmins,
          onClick: () => onClose()
        },
        {
          title: 'Funder Report',
          icon: <AnalyticsOutlined fontSize="large" />,
          path: '/accounting/funders',
          hidden: !ability.can('full', 'Invoice') || !showAccountingForAdmins,
          onClick: () => onClose()
        },
        {
          title: 'Settings',
          icon: <Settings />,
          path: '/settings',
          hidden: !session?.data?.currentRole?.is_admin,
          onClick: () => onClose()
        },
        {
          title: 'Documentation',
          icon: <FindInPage />,
          hidden: (!session.data?.currentRole?.is_admin && !ability.can('full', 'Invoice')) || !showAccountingForAdmins,
          onClick: () => {
            window.open(`${import.meta.env.VITE_SERVER_URL || ''}/admin/documentation`, '_blank')
            onClose()
          }
        }
      ]
    }
  ]

  const location = useLocation()
  // const auth = useSession();

  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))

  const content = (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        <Box className="tablet:flex justify-center hidden bg-grey-800" p={1}>
          <Link href="/" className="flex items-center" underline="none">
            <img src="/static/images/logo-white.png" alt="logo" height="50px" />
          </Link>
        </Box>
        <Box sx={{ flexGrow: 1, border: '1px solid #E3E8EF' }}>
          {sections.map((section) => (
            <DashboardSidebarSection
              key={section.title}
              path={location.pathname}
              sx={{
                mt: 2,
                '& + &': {
                  mt: 2
                }
              }}
              {...section}
            />
          ))}
        </Box>
      </Box>
      {/* </Scrollbar> */}
    </>
  )

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            borderRightColor: 'divider',
            borderRightStyle: 'solid',
            borderRightWidth: (theme) => (theme.palette.mode === 'dark' ? 1 : 0),
            color: '#364152',
            width: 256,
            paddingTop: '64px'
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    )
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          color: '#364152',
          width: 280
        }
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  )
}

export default DashboardSidebar
