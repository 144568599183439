import { Box, Divider, Grid, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material'
import { DeleteOutlinedIcon, EditOutlinedIcon, InsertDriveFileOutlinedIcon, MoreHorizIcon } from '../../assets/icons'
import FileNotFoundDialog from './FileNotFoundDialog'
import { File } from 'react-feather'
import { useState } from 'react'
import { FileOutput } from '../../types/procedureOutputs'
import { Download } from '../../icons/Download'
import CheckCircle from '../../icons/CheckCircle'
import { formatDurationShorthand, isExcelFile, truncate } from '../../utils/helpers'
import { trpc } from '../../core/trpc'
import toast from 'react-hot-toast'
import bytesToSize from '../../utils/bytesToSize'
import { ErrorOutline } from '@mui/icons-material'
import { downloadFileFromLink } from '../../models/file'

type FileItemProps = {
  fileNotFoundDialogOpen: boolean
  setFileNotFoundDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
  setFileToRename?: React.Dispatch<React.SetStateAction<FileOutput | undefined>>
  setFileToDescribe?: React.Dispatch<React.SetStateAction<FileOutput | undefined>>
  onPreviewFile: () => void
  file: FileOutput
  handleDelete?: (file: FileOutput, callback?: () => void) => void
  updateFileContract: (payload: { is_contract: boolean; id: string }) => void
  updateIsLoading: boolean
  hideFileActions?: boolean
}

const FileItem = ({
  fileNotFoundDialogOpen,
  setFileNotFoundDialogOpen,
  setFileToRename,
  file: f,
  handleDelete,
  setFileToDescribe,
  onPreviewFile,
  updateFileContract,
  updateIsLoading,
  hideFileActions
}: FileItemProps) => {
  const [fileActionsEl, setFileActionsEl] = useState<HTMLElement>()
  const [showActionsButton, setShowActionsButton] = useState<string>()
  const getViewFileLink = trpc.file.viewFileLink.useMutation()

  const downloadFile = (file: FileOutput) => {
    getViewFileLink
      .mutateAsync({ id: file.id })
      .then((url) => downloadFileFromLink(file, url))
      .catch((e) => toast.error(`Failed to download file. ${e}`))
  }

  const menuItems = (file?: FileOutput) => [
    {
      title: 'Download',
      icon: <Download fontSize="small" />,
      action: (file: FileOutput) => downloadFile(file)
    },
    {
      title: 'Rename',
      icon: <EditOutlinedIcon fontSize="small" />,
      action: (file: FileOutput) => setFileToRename?.(file)
    },
    {
      title: 'Add description',
      icon: <InsertDriveFileOutlinedIcon fontSize="small" />,
      action: (file: FileOutput) => setFileToDescribe?.(file)
    },
    {
      title: `${file?.is_contract ? 'Unmark' : 'Mark'} as contract file`,
      icon: <CheckCircle fontSize="small" />,
      action: (file: FileOutput) => updateFileContract({ id: file.id, is_contract: !file.is_contract })
    },
    {
      title: 'Delete',
      icon: <DeleteOutlinedIcon fontSize="small" />,
      action: (file: FileOutput) => handleDelete?.(file)
    }
  ]

  return (
    <Grid
      key={f.id}
      container
      item
      onMouseEnter={() => setShowActionsButton(f.id)}
      onMouseLeave={() => setShowActionsButton(undefined)}
      justifyContent="space-between"
      alignItems="center"
      sx={{ paddingY: '5px', borderBottom: '1px solid #E3E8EF' }}
    >
      <Grid onClick={(e) => onPreviewFile()} item display="flex" alignItems="center" className="cursor-pointer">
        <Tooltip title={isExcelFile(f) ? 'cannot preview excel files' : ''}>
          <Box className="flex items-center gap-1">
            <IconButton
              className="mr-2 bg-white rounded-full w-fit h-fit"
              sx={{
                border: '1px solid lightgray'
              }}
            >
              <File className="bg-white text-grey-600 h-3 w-3" />
            </IconButton>
            <Typography className={`text-sm font-medium self-center ${f.renamed ? 'text-primary-900' : 'text-grey-700'}`}>{f.name}</Typography>
            {f.is_file_in_azure === false && <ErrorOutline fontSize="small" className="self-center" color="warning" />}
            {f.is_contract && (
              <Tooltip title="Contract File">
                <CheckCircle color="success" fontSize="small" />
              </Tooltip>
            )}
            <Typography className="text-grey-500 text-sm font-normal ml-2 self-center">{bytesToSize(f.size)}</Typography>
            {f.description ? (
              <Typography className="text-grey-500 text-xs font-normal ml-2 self-center">{truncate(f.description, { length: 60 })}</Typography>
            ) : null}
          </Box>
        </Tooltip>
      </Grid>
      <Grid className="flex items-center">
        {showActionsButton === f.id && !hideFileActions && (
          <IconButton className="p-0.5" onClick={(e) => setFileActionsEl(e.currentTarget)}>
            <MoreHorizIcon />
          </IconButton>
        )}
        <Typography className="text-grey-500 text-sm font-normal ml-2">{formatDurationShorthand(new Date(f.created_at))} ago</Typography>
      </Grid>
      {fileNotFoundDialogOpen && <FileNotFoundDialog open={fileNotFoundDialogOpen} setOpen={setFileNotFoundDialogOpen} />}
      <Menu onClose={() => setFileActionsEl(undefined)} anchorEl={fileActionsEl} open={Boolean(fileActionsEl) && showActionsButton === f.id}>
        {menuItems(f).map((item) => (
          <MenuItem
            onClick={(e) => {
              e.stopPropagation()
              item.action(f)
              setFileActionsEl(undefined)
            }}
            key={item.title}
          >
            {item.title === 'Delete' && <Divider />}
            {item.icon}
            <Typography className="text-grey-700 ml-1">{item.title}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Grid>
  )
}

export default FileItem
